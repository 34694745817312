//
// Use this to overwrite Bootstrap and theme variables
//
$primary-black: #000000;
$primary-yellow: #FFEB3A;
$primary-white: #FFFFFF;
$primary-salad: #00EC89;
$primary-green: #00C874;

$secondary-dis: #00000066;
$secondary-obj: #0000001A;
$secondary-sel: #0000000D;

$tertiary-30: #0000004D;
$tertiary-20: #00000033;
$tertiary-10: #0000001A;
$tertiary-dark: #FFFFFF33;

// --------------------------
$black: #000;
$white: #fff;
//$dark: $theme-light-gray;
//$secondary: $dark;
//$light: $theme-light;

$dark: #e5e5e5;
$secondary: #e5e5e5;
$light: #f1f1f1;

$body-color: $secondary-dis;

// Theme Colors

$theme-colors: (
  "light":      $light,
  "dark":       $dark,
  "white":      $primary-white,
  "black":      $primary-black,
  "yellow":     $primary-yellow,

  "primblack":  $primary-black,
  "primyellow": $primary-yellow,
  "primwhite":  $primary-white,
  "primsalad":  $primary-salad,
  "primgreen":  $primary-green,

  "secdis":     $secondary-dis,
  "secobj":     $secondary-obj,
  "secsel":     $secondary-sel,

  "ter3":      $tertiary-30,
  "ter2":      $tertiary-20,
  "ter1":      $tertiary-10,
  "terd":      $tertiary-10,
) !default;

// Container

$grid-gutter-width: 16px;

// Radius

$border-radius: .75rem;
$border-radius-sm: .5rem;
$border-radius-lg: 1.5rem;

// Input
$input-color: $black;
$input-border-color: $secondary-dis;
$input-border-width: 1px;
$input-border-radius: $border-radius;
$input-font-weight: 500;
$input-bg: $white;
$input-placeholder-color: $secondary-dis;

$input-padding-x: 1rem;
$input-padding-y: 1rem;

$input-focus-bg: $white;
$input-focus-border-color: $primary-green;
$input-focus-box-shadow: 0px 0px 4px #00C874;

$form-check-input-bg: $primary-yellow;
$form-check-input-border: 1px solid $black;
$form-check-input-border-radius: 50%;

$form-check-input-checked-bg-image: url('data:image/svg+xml,<svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 3.5L3.66667 6L9 1" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>');
$form-check-input-checked-bg-color: $primary-yellow;
$form-check-input-checked-border-color: $black;

$form-check-radio-checked-bg-image: url('data:image/svg+xml,<svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 3.5L3.66667 6L9 1" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>');

$form-select-indicator: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M3.97122 8.38767C3.97141 8.17492 4.05608 7.97096 4.20661 7.82061C4.35706 7.67034 4.56102 7.58594 4.77366 7.58594C4.98631 7.58594 5.19026 7.67034 5.34072 7.82061L11.9956 14.4755L18.6505 7.82061C18.8026 7.67887 19.0038 7.6017 19.2117 7.60537C19.4196 7.60904 19.6179 7.69326 19.765 7.84028C19.912 7.9873 19.9962 8.18564 19.9999 8.39353C20.0035 8.60142 19.9264 8.80261 19.7846 8.95473L12.5627 16.1767C12.4122 16.327 12.2083 16.4114 11.9956 16.4114C11.783 16.4114 11.579 16.327 11.4286 16.1767L4.20661 8.95473C4.05608 8.80439 3.97141 8.60042 3.97122 8.38767Z" fill="black"/> </svg>') !default;
$form-select-bg-size: 24px 24px;

// Button
$input-btn-padding-y: .875rem;
$btn-font-weight: 700;
$btn-disabled-opacity: 1;

// Text
$font-family-base: 'DM Sans', sans-serif;
$font-family-sans-serif: 'DM Sans', sans-serif;

$font-size-base: 1rem !default;
$font-size-sm: $font-size-base * .685 !default;
$font-size-lg: $font-size-base * 1.125 !default;

$line-height-base: 1.5;

$headings-color: $primary-black;

$h1-font-size: 48px;
$h2-font-size: 32px;
$h3-font-size: 20px;
$h4-font-size: 18px;
$h5-font-size: 16px;
$h6-font-size: 14px;

$extra-small-font-size: 12px;

// Modal
$modal-sm: 460px;
$modal-md: 560px;
$modal-content-border-radius: 1rem;

// Navigation
$navigation-width: 250px;
$nav-tabs-link-active-color: null;
$nav-link-padding-x: .625rem;
$nav-link-padding-y: .375rem;
$nav-pills-border-radius: .5rem;

// Badges
$badge-font-size: .6875em;
$badge-border-radius: .25rem;

// Carousel
$carousel-indicator-width:           10px!default;
$carousel-indicator-height:          10px!default;
$carousel-indicator-hit-area-height: 10px!default;
$carousel-indicator-spacer:          10px!default;
$carousel-indicator-opacity:         1!default;
$carousel-indicator-active-bg:       $black!default;
$carousel-indicator-active-opacity:  1!default;
$carousel-indicator-transition:      opacity .6s ease!default;

// Shadow
$box-shadow:                  0 .5rem 1rem rgba($black, .15);
$box-shadow-sm:               0 .125rem .25rem rgba($black, .075);
$box-shadow-lg:               0px -8px 16px rgba(7, 6, 123, 0.05);
$box-shadow-inset:            inset 0 1px 2px rgba($black, .075);
