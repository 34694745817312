//
// Buttons
//

.btn:not([class*=btn-outline]) {
    border-color: transparent !important;
}

.btn-sm {
    letter-spacing: .00625rem
}

//
// Alternate buttons
//

@each $color, $value in $theme-colors {
    .btn-soft-#{$color} {
        @include button-soft-variant($value);
    }
}

@each $color, $value in $theme-colors {
    .btn-#{$color} {
      @include button-variant($value, $value);
    }
}

.btn-link:hover {
    opacity: 0.8;
}

.btn:not(.btn-icon) {

    > svg {
        height: 1rem;
        width: 1rem;
    }
}

//
// Buttons with icons
//

.btn-icon {
    line-height: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false));
    width: add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false));

    > svg {
        @include size(1rem);
    }
}

.btn-icon.btn-sm {
    height: add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false));
    width: add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false));

    > svg {
        @include size(.75rem);
    }
}

.btn-icon.btn-lg {
    height: add($input-line-height * 1em, add($input-padding-y-lg * 2, $input-height-border, false));
    width: add($input-line-height * 1em, add($input-padding-y-lg * 2, $input-height-border, false));

    > svg {
        @include size(1.125rem);
    }
}