//
// Outline Border
//

.border-outline {
    box-shadow: 0 0 0 $border-width $body-bg;
}

@each $width, $value in $border-widths {
    .border-outline.border-#{$width} {
        box-shadow: 0 0 0 $value $body-bg !important;
    }
}

@each $color, $value in $theme-colors {
    .border-outline.border-#{$color} {
        box-shadow: 0 0 0 $border-width $value !important;
    }
}
