//
// Dropdown
//

.dropdown-menu {
    box-shadow: $dropdown-box-shadow;
    padding: $dropdown-padding-y;
}

.dropdown-item {
    border-radius: $border-radius;
}