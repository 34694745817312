//
// Nav
//

.toast {
    border: 0;
    backdrop-filter: $backdrop-filter;
    display: block;
    max-width: 100%;
    width: auto;
}

.toast-container {
    margin-left: auto;
    margin-right: auto;
    padding: .75rem 1.5rem;
    width: auto;

    > :not(:last-child) {
        margin-bottom: .5rem;
    }
}

@include media-breakpoint-up(xl) {

    .toast {
        max-width: $toast-max-width - 38px;
    }

}
