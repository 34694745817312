// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant(
  $background,
  $border,
  $color: color-contrast($background),
  $hover-background: darken($background, 5%),
  $hover-border: darken($border, 5%),
  $hover-color: color-contrast($hover-background),
  $active-background: darken($background, 10%),
  $active-border: darken($border, 10%),
  $active-color: color-contrast($active-background)
) {
  color: $color;
  @include gradient-bg($background);
  border-color: $border;
  @include box-shadow($btn-box-shadow);

  &:hover {
    color: $hover-color;
    @include gradient-bg($hover-background);
    border-color: $hover-border;
  }

  .btn-check:focus + &,
  &:focus {
    color: $hover-color;
    @include gradient-bg($hover-background);
    border-color: $hover-border;
    @if $enable-shadows {
      @include box-shadow($btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), .5));
    } @else {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), .5);
    }
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active,
  &.active,
  .show > &.dropdown-toggle {
    color: $active-color;
    background-color: $active-background;
    // Remove CSS gradients if they're enabled
    background-image: if($enable-gradients, none, null);
    border-color: $active-border;

    &:focus {
      @if $enable-shadows {
        @include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), .5));
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), .5);
      }
    }
  }

  &:disabled,
  &.disabled {
    color: $color;
    background-color: $background;
    // Remove CSS gradients if they're enabled
    background-image: if($enable-gradients, none, null);
    border-color: $border;
  }
}

@mixin button-outline-variant(
  $color,
  $color-hover: color-contrast($color),
  $active-background: $color,
  $active-border: $color,
  $active-color: color-contrast($active-background)
) {
  color: $color;
  border-color: $color;

  &:hover {
    color: $color-hover;
    background-color: $active-background;
    border-color: $active-border;
  }

  .btn-check:focus + &,
  &:focus {
    box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
  }

  .btn-check:checked + &,
  .btn-check:active + &,
  &:active,
  &.active,
  &.dropdown-toggle.show {
    color: $active-color;
    background-color: $active-background;
    border-color: $active-border;

    &:focus {
      @if $enable-shadows {
        @include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width rgba($color, .5));
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: 0 0 0 $btn-focus-width rgba($color, .5);
      }
    }
  }

  &:disabled,
  &.disabled {
    color: $color;
    background-color: transparent;
  }
}

@mixin button-soft-variant(
    $clr,
    $background: rgba-to-rgb(rgba($clr, 0.15)),
    $border: rgba-to-rgb(rgba($clr, 0.15)),
    $color: $clr,
    $hover-background: darken($background, 2%),
    $hover-border: darken($border, 2%),
    $hover-color: $color,
    $active-background: darken($background, 2.5%),
    $active-border: darken($border, 2.5%),
    $active-color: $color,
  ) {
    color: $color;
    background: $background;
    border-color: $border;
    @include box-shadow($btn-box-shadow);

    &:hover {
        color: $color;
        background: $hover-background;
        border-color: $hover-border;
    }

    .btn-check:focus + &,
    &:focus {
      color: $hover-color;
      @include gradient-bg($hover-background);
      border-color: $hover-border;
      @if $enable-shadows {
        @include box-shadow($btn-box-shadow, 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), .5));
      } @else {
        // Avoid using mixin so we can pass custom focus shadow properly
        box-shadow: 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), .5);
      }
    }

    .btn-check:checked + &,
    .btn-check:active + &,
    &:active,
    &.active,
    .show > &.dropdown-toggle {
      color: $active-color;
      background-color: $active-background;
      // Remove CSS gradients if they're enabled
      background-image: if($enable-gradients, none, null);
      border-color: $active-border;

      &:focus {
        @if $enable-shadows {
          @include box-shadow($btn-active-box-shadow, 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), .5));
        } @else {
          // Avoid using mixin so we can pass custom focus shadow properly
          box-shadow: 0 0 0 $btn-focus-width rgba(mix($color, $border, 15%), .5);
        }
      }
    }

    &:disabled,
    &.disabled {
      color: $color;
      background-color: $background;
      // Remove CSS gradients if they're enabled
      background-image: if($enable-gradients, none, null);
      border-color: $border;
    }
}