//
// Nav
//

.nav-pills {
  background: $white;
  border-radius: 0;
  padding: 0;

  .nav-link {
    color: $black;

    &.active {
      box-shadow: 0 1px 3px rgba(0, 0, 0, .05);
      background: rgba(0, 0, 0, 0.05);
    }
  }
}
