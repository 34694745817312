/* You can add global styles to this file, and also import other style files */
@import './assets/styles/template';
@font-face {
  font-family: 'SF Pro Rounded';
  font-weight: normal;
  src: url('/assets/styles/fonts/FontsFree-Net-SF-Pro-Rounded-Regular.ttf');
}
@font-face {
  font-family: 'SF Pro Rounded';
  font-weight: bold;
  src: url('/assets/styles/fonts/FontsFree-Net-SF-Pro-Rounded-Bold.ttf');
}
