//
// Layout
//

html,
body,
.layout {
    overflow-x: hidden;
    height: 100%;
}

.layout {
    display: flex;
    flex-direction: column;
}

.navigation {
    border-top: $border-width solid $border-color;
    padding: 0 $container-padding-x;
    flex-wrap: nowrap;
    min-height: 58px;
}

.main,
.sidebar {
    overflow: hidden;
}

.main {
    position: relative;
    flex: 1;
}

.sidebar {
    width: 100%;
    height: -webkit-fill-available; // Mobile fix
    height: calc(100vh - 58px);
}

.chat-footer {
    //  width: calc(100% + (#{$grid-gutter-width - math.div($grid-gutter-width, 5)}));
    //  margin-left: - (math.div($grid-gutter-width, 5) * 2);
    width: 100%;
    z-index: 1;

    @-moz-document url-prefix() {

        &::before {
            background: rgba($backdrop-filter-bg, 1);
        }

    }
}

.chat-form {
    position: relative;
    padding: math.div($grid-gutter-width, 5);
}

@include media-breakpoint-up(xl) {

    .main,
    .sidebar,
    .navigation {
        height: 100vh;
    }

}

@include media-breakpoint-up(xl) {

    .layout {
        flex-direction: row;
    }

    .navigation {
        width: $navigation-width;
        padding: 1.5rem 0;
        border: 0;
    }

    .sidebar {
        width: $sidebar-width;
    }

}

@include media-breakpoint-down(xl) {

    .sidebar {
        order: 1;
    }

    .navigation {
        order: 2;
    }

    .main {
        position: fixed;
        background: $body-bg;
        top: 0;
        left: 0;
        bottom: 0;
        transform: translateX(100%);
        transition: visibility .4s ease, -webkit-transform .4s ease, transform .4s ease;
        visibility: hidden;
        z-index: 3;
        order: 3;
        @include size(100%);

        &.is-visible {
            transform: translateX(0);
            visibility: visible;
        }
    }
}
